import {MDCTopAppBar} from "@material/top-app-bar";
import {Howl, Howler} from 'howler';

const bodyTopPadding = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('padding-top'), 10); 

const btnInfo = document.querySelector("#btn_info");

const elAboutPage = document.querySelector("#about-page");
const elMainPage = document.querySelector("#main-page");
const elTopAppBar = document.querySelector(".mdc-top-app-bar");

const topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar'));
topAppBar.setScrollTarget(elMainPage);

elMainPage.addEventListener('scroll', () => {
    elTopAppBar.style.removeProperty('box-shadow');
});
/*
elAboutPage.addEventListener('transitionend', () => {
    if (elAboutPage.style.top == "0%") {
        // on top
        elMainPage.style.display = "none";
        elAboutPage.style.display = "block";
        elTopAppBar.style.removeProperty('box-shadow');

        // elTopAppBar.style.removeProperty('box-shadow');
        // console.log(tempBoxShadow)
        // elTopAppBar.style.boxShadow = tempBoxShadow;
    } else {
        elMainPage.style.display = "block";
        elAboutPage.style.display = "none";

        elTopAppBar.style.removeProperty('box-shadow');        
    }
});
*/

btnInfo.addEventListener('click', () => {
    elAboutPage.style.display = "block";
    let open = elAboutPage.style.top == "0%";
    
    if (open) {
        topAppBar.setScrollTarget(elMainPage);
        elAboutPage.style.top = "-100%";
        //elMainPage.style.overflowY = "scroll";
    } else {
        //topAppBar.setScrollTarget(elAboutPage);
       // elMainPage.style.overflowY = "hidden";
        elAboutPage.style.top = "0%";
        elTopAppBar.style.boxShadow = "none";
    }

    // elAboutPage.style.display = "block";
    // elMainPage.style.display = "block";
});

let tProjectCredits = document.querySelector("#template_project_credits");
let tSonette = document.querySelector("#template_sonette");

let elCreditsSection = document.querySelector("#credits");
let elSonettesSection = document.querySelector(".sonette_container");

var maxLoudness = -100;
var currentOverlay = null;


async function createDOM(){
    const dataUrl = "assets/data.json";
    let data = await fetch(dataUrl);
    let projectData = await data.json();

    projectData
        .data
        .sonettes
        .forEach(sonette => {
            let clone = document.importNode(tSonette.content, true);

            let elWrapper = clone.querySelector(".mdc-layout-grid__cell");
            sonette
                .audio
                .forEach(path => {
                    // Create el audio
                    const elAudio = document.createElement("audio");
                    elAudio.src = path;
                    elAudio.setAttribute("preload", "true");
                    elWrapper.appendChild(elAudio);

                    //var sound = new Howl({src: path, format: ['mp3']});
                    //elAudio.howler = sound;
                });

            //let elAudio = clone.querySelector("audio");
            let elSonette = clone.querySelector(".sonette");
            let elSonetteOverlay = clone.querySelector(".sonette_overlay");

            elWrapper
                .classList
                .add("mdc-layout-grid__cell--span-" +
                        sonette.size);
            //elAudio.src = sonette.audio;
            elSonette.src = sonette.img
            elSonetteOverlay.src = sonette.overlay;

            elSonettesSection.appendChild(clone);
        });

    projectData
        .data
        .credits
        .forEach(project => {
            let clone = document.importNode(tProjectCredits.content, true);

            let elTitle = clone.querySelector(".project_title");
            let elCredits = clone.querySelector(".project_credits");
            let elDescEN = clone.querySelector(".project_desc_en");
            let elDescFR = clone.querySelector(".project_desc_fr");
            elTitle.innerHTML = project.title;
            elCredits.innerHTML = project.credits;
            elDescEN.innerHTML = project.en_desc;
            elDescFR.innerHTML = project.fr_desc;

            elCreditsSection.appendChild(clone);
        });
}

async function main() {
    try {
       //await createDOM()

        const sonettes = Array.from(document.querySelectorAll(".sonette"));
        const allAudios = Array.from(document.querySelectorAll("audio"));
        
        allAudios.forEach(elAudio => {
            var sound = new Howl({src: elAudio.src, format: ['mp3']});
            elAudio.howler = sound;
        }) 

        allAudios.forEach(a => {
            a.addEventListener("pause", () => {
                const elOverlay = a
                    .parentElement
                    .querySelector(".sonette_overlay");
                elOverlay.style.display = "none";
            });
            a.howler.on('stop', () => {
              const elOverlay = a
                    .parentElement
                    .querySelector(".sonette_overlay");
              elOverlay.style.display = "none";
            })
        });

        sonettes.forEach(s => {
            s.addEventListener('click', () => {
              const elAudios = Array.from(s.parentElement.querySelectorAll("audio"));
              const elAudio = elAudios[Math.floor(Math.random() * elAudios.length)];

              allAudios.forEach(a => {
                    //a.pause()
                    //a.currentTime = 0;
                    
                    if (elAudio.howler != a.howler) {
                      a.howler.stop();
                      // the other one is stopped
                    } else {
                      //console.log("howler not stopped")
                    }
                });

                const elOverlay = s
                    .parentElement
                    .querySelector(".sonette_overlay");
                
                if (elAudio.howler.playing()) {
                    elAudio.howler.stop()
                } 
                    elAudio
                    .howler
                    .play();
                elOverlay.style.display = "block";
                currentOverlay = elOverlay;
                maxLoudness = -100;
                elAudio
                    .howler
                    .once('end', () => {
                        elOverlay.style.display = "none";
                    });

                elAudio.addEventListener('complete', () => {
                    elOverlay.style.display = "none";
                }, {once: true});
            });
        });

        setupAudioAnalyserHowler();

    } catch (e) {
        console.log("Exception occured");
        console.log(e);
    }
}

function setupAudioAnalyserHowler() {
    var analyser = Howler.ctx.createAnalyser();
    Howler.masterGain.connect(analyser);

    analyser.connect(Howler.ctx.destination);

    analyser.fftSize = 32;
    var bufferLength = analyser.frequencyBinCount;
    var dataArray = new Uint8Array(bufferLength);

    analyser.getByteTimeDomainData(dataArray);

    function renderFrame() {
      analyser.getByteFrequencyData(dataArray);
      
    var loudness = dataArray.reduce((a, b) => a + b);
      
     if (loudness > maxLoudness) {
       maxLoudness = loudness;
     }
 
     if (currentOverlay) {
       currentOverlay.style.opacity = loudness / maxLoudness;
       if (currentOverlay.style.display == "none") {
        currentOverlay.style.display = "block";
       }
     }
 
     requestAnimationFrame(renderFrame);
   }
   renderFrame();
}

main();